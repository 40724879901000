/*
	System settings for transitioning between different stages of development
		-Copy/Paste section into active section when transitioning
		-Terser will remove all other comments when transpiling
*/
/*
	//PRODUCTION
	export const PRODUCTION_STATUS = true;
	export const API_ENDPOINT = 'https://api.buteo.app/';
	export const APP_VERSION = '0.9.0';

	//SJ_STAGING
	export const PRODUCTION_STATUS = true;
	export const API_ENDPOINT = 'https://buteo.sabrejunkie.com/api/';

	//Development
	export const PRODUCTION_STATUS = false;
	export const API_ENDPOINT = 'http://localhost/fitdev/FitAPI/';

*/

/**************************************************/
// Interface Settings/URLs
/**************************************************/
export const PRODUCTION_STATUS = true;
export const API_ENDPOINT = 'https://api.buteo.app/';
export const APP_VERSION = '0.9.5';

export const BUTEO_BROADCAST_CHANNEL = 'BUTEO_BROADCAST';

export const URL_DEVICES = '/devices/';
export const URL_LOGIN = '/login/';
export const URL_CREATE = '/create/';
export const URL_MY_PROFILE = '/my-profile/';
export const URL_PROFILES = '/profiles/';
export const URL_TEAMS = '/teams/';
export const URL_ANALYTICS = '/analytics/';
export const URL_ATHLETE_ASSIGNMENTS = '/coaching/athlete-assignments/';
export const URL_TEAM_WORKOUTS = '/team-workouts/';
export const URL_TEAM_BLOCKS = '/team-blocks/';
export const URL_ONBOARDING = '/onboarding/';
export const URL_ACTIVE_WORKOUT = `${URL_TEAM_WORKOUTS}active/`;
//Personal Workouts/Blocks
export const URL_PERSONAL_BLOCK_TEMPLATE = '/personal/workouts/saved-blocks/';
export const URL_PERSONAL_BLOCK_TEMPLATE_NEW = '/personal/workouts/new-block/';
export const URL_PERSONAL_ACTIVE_BLOCK = '/personal/workouts/active-blocks/';
export const URL_PERSONAL_QUICK_WORKOUT = '/personal/workouts/quick-workout/'

export const COMPANY_NAME = 'BUTEO';
export const DOMAIN_NAME = 'buteo.app';
export const EXAMPLE_EMAIL = `my-email@${DOMAIN_NAME}`;

/**************************************************/
// Country Variables
/**************************************************/
export const USA = 'USA';
export const CANADA = 'Canada';
export const DATABASE_UNKNOWN_COUNTRY = 'UNKNOWN';
export const INTERFACE_OTHER_COUNTRY = 'other';


/**************************************************/
//PERMISSION KEYS
export const PERM_SET = 'deptPermissions';
export const PERM_DEPT = 'depts';
export const PERM_TEAM = 'teams';
/**************************************************/
// DEPTS
/**************************************************/
export const ATHLETE_DEPT_ID = 7;
export const STRENGTH_AND_CONDITIONING_DEPT_ID = 1;
export const COACHING_STAFF_DEPT_ID = 2;
export const ADMIN_DEPT_ID = 10000;
/**************************************************/
// Permission, User Settings etc
/**************************************************/
export const BUTEO_SYSTEM_USER_ID = 1;
export const VIEWER_LEVEL = 0;
export const ATHLETE_LEVEL = 100;
export const DEVICE_MASTER_ACCOUNT_VALUE = 100; //users
export const DEVICE_MASTER_LEVEL = 1501; //permissions
export const DEVICE_LEVEL = 1500;
export const COACHING_ASSISTANT_LEVEL = 1001;
export const COACHING_ASSISTANT_MASTER_LEVEL = 1002;
export const ASSISTANT_LEVEL = 1000;
export const OPERATIONS_LEVEL = 2000;
export const COACH_LEVEL = 2001;
export const DIRECTOR_LEVEL = 3000;
export const OWNER_LEVEL = 4000;
export const OVERSEER_LEVEL = 5000;
export const GOD_LEVEL = 10000;

export const SESSION_IDLE_MINUTES = 15;
export const SESSION_TIME_STRING = 'lastRequest'; //For handling/limiting idling of users
export const GOD_LABEL = 'God Tier';
export const OVERSEER_LABEL = 'Director';
export const DEVICE_ACCOUNT_LABEL = 'Device Master';

export const ORGANIZATION_TYPES = {
	university: 64,
	highSchool: 65,
	professionalTeam: 66
}
//Manager Labels for permissions, change logs, etc

export const DEPT_MANAGER_LABELS = [
	/*{label: 'Athlete', value: ATHLETE_LEVEL},*/
	/*{label: 'Coaching Assistant', value: COACHING_ASSISTANT_LEVEL},*/
	{label: GOD_LABEL, value: GOD_LEVEL, deviceAccount: false, preventSelection: false},
	{label: 'Overseer', value: OVERSEER_LEVEL, deviceAccount: false, preventSelection: false},
	{label: 'Owner', value: OWNER_LEVEL, deviceAccount: false, preventSelection: false},
	{label: 'Director', value: DIRECTOR_LEVEL, deviceAccount: false, preventSelection: false},
	{label: 'Head Coach', value: COACH_LEVEL, deviceAccount: false, preventSelection: false},
	{label: 'Operations', value: OPERATIONS_LEVEL, deviceAccount: false, preventSelection: false},
	{label: 'Assistant Coach', value: ASSISTANT_LEVEL, deviceAccount: false, preventSelection: false},
	{label: DEVICE_ACCOUNT_LABEL, value: DEVICE_MASTER_LEVEL, deviceAccount: true, preventSelection: true}
];
//AIT manager labels for permissions, change logs, etc
//DELETE THIS EVENTUALLY IF NOT NECESSARY
export const ATHLETE_MANAGER_LABELS = [
	...DEPT_MANAGER_LABELS,
	{label: '__________________', value: null, disabled: true},
	{label: 'Coaching Assistant', value: COACHING_ASSISTANT_LEVEL, preventSelection: true},
	{label: 'Athlete', value: ATHLETE_LEVEL, preventSelection: false},
	{label: 'Viewer', value: VIEWER_LEVEL , preventSelection: true},
];
export const DEPT_MINIMUM_REQUIRED_LEVELS =[
	{deptID: STRENGTH_AND_CONDITIONING_DEPT_ID, minimum: ASSISTANT_LEVEL, maximum: OVERSEER_LEVEL},
	{deptID: COACHING_STAFF_DEPT_ID, minimum: ASSISTANT_LEVEL, maximum: OVERSEER_LEVEL},
	{deptID: ATHLETE_DEPT_ID, minimum: VIEWER_LEVEL, maximum: ATHLETE_LEVEL}
]

//export const ATHLETE_LABELS = [{"id":1,"organizationTypeID":64,"labelID":63,"redShirt":0,"redShirtAlt":null,"yearsRemaining":4,"labelOrder":0,"active":1,"label":"Signee\/Recruit"},{"id":2,"organizationTypeID":64,"labelID":11,"redShirt":1,"redShirtAlt":6,"yearsRemaining":5,"labelOrder":0,"active":1,"label":"Redshirt Freshman"},{"id":3,"organizationTypeID":64,"labelID":6,"redShirt":0,"redShirtAlt":11,"yearsRemaining":4,"labelOrder":0,"active":1,"label":"Freshman"},{"id":4,"organizationTypeID":64,"labelID":12,"redShirt":1,"redShirtAlt":7,"yearsRemaining":4,"labelOrder":0,"active":1,"label":"Redshirt Sophomore"},{"id":5,"organizationTypeID":64,"labelID":7,"redShirt":0,"redShirtAlt":12,"yearsRemaining":3,"labelOrder":0,"active":1,"label":"Sophomore"},{"id":6,"organizationTypeID":64,"labelID":13,"redShirt":1,"redShirtAlt":8,"yearsRemaining":3,"labelOrder":0,"active":1,"label":"Redshirt Junior"},{"id":7,"organizationTypeID":64,"labelID":8,"redShirt":0,"redShirtAlt":13,"yearsRemaining":-2,"labelOrder":0,"active":1,"label":"Junior"},{"id":8,"organizationTypeID":64,"labelID":14,"redShirt":1,"redShirtAlt":9,"yearsRemaining":2,"labelOrder":0,"active":1,"label":"Redshirt Senior"},{"id":9,"organizationTypeID":64,"labelID":9,"redShirt":0,"redShirtAlt":14,"yearsRemaining":1,"labelOrder":0,"active":1,"label":"Senior"},{"id":10,"organizationTypeID":64,"labelID":10,"redShirt":0,"redShirtAlt":null,"yearsRemaining":1,"labelOrder":0,"active":1,"label":"5th Year"},{"id":11,"organizationTypeID":64,"labelID":17,"redShirt":0,"redShirtAlt":null,"yearsRemaining":1,"labelOrder":0,"active":1,"label":"Grad"}];


export const COLOR_SCALES = {
	green: '#00cd1d',
	yellow: '#f90',
	red: '#f00',
}


export const ATHLETE_ASSIGNMENT_TYPES = {
	inactive: 0,
	squads: 1,
	positions: 2,
}

/**************************************************/
// LABELS
/**************************************************/

/**************************************************/
// STAT SETTINGS
/**************************************************/
export const MAX_LEADERBOARD_KPIS = 8;
/**************************************************/
// MOMENT CONSTANTS
/**************************************************/
export const MOMENT_MIN_DATE = '2023-01-01';
export const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const MOMENT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const SEARCH_MINIMUM_LENGTH = 2;
export const SEARCH_DEBOUNCE_DELAY = 350;
export const SHORT_DEBOUNCE_DELAY = 200;
export const TEXTFIELD_DEBOUNCE_DELAY = 750;
/**************************************************/
// MISC -
/**************************************************/
export const GOOGLE_MAP_API_KEY = 'AIzaSyDSZYtyWHBkVRgWv4YDHoRaLTAtb2nqEHU'; //For Actor Show Details Map
export const GOLD_COLOR = '#FFd700';
/************************************************/
// STYLES
export const BUTTON_INPUT_HEIGHT_MATCH = {height: '53px'};
export const BUTTON_INPUT_HEIGHT_MATCH_SMALL = {height: '37px'}
export const ICON_SX = {fontSize: 20, marginRight: '6px'};
export const PAPER_STYLE = {padding: '24px'};
export const PAPER_STYLE_SMALL = {padding: '12px'};
export const DISABLED_STYLE = {alpha: '50%'};
export const FUTURE_STYLE = {alpha: '80%'}
export const BACKGROUND_COLOR_TRANSPARENCY = '.05';
export const TYPOGRAPHY_BODY_STYLE = {marginBottom: '12px'}
export const DISABLED_OPACITY = .4;
export const ALERT_STYLE = TYPOGRAPHY_BODY_STYLE;

export const HORIZONTAL_OVERFLOW_STYLES = {
	display: 'block',
	overflow: 'scroll',
	overflowY: 'hidden',
	overflowX: 'auto',
	width: '100%',
	height: 'auto'
}

export const DISPLAY_ADJUSTMENT_STRINGS = {
	td: 'table-cell',
	text: 'inline',
}

export const SNACKBAR_DEFAULT_PROPS = {
	maxSnack: 4,
	anchorOrigin: {
		horizontal: 'right',
		vertical: 'top'
	},
	autoHideDuration: 2750,
}

export const SINGLE_FORM_FLEX_STYLE = {
	display: 'flex',
	flexDirection : 'column',
	justifyContent: 'center',
	alignItems: 'center !important',
	flexWrap: 'wrap',
	alignContent: 'center',
}

export const DESKTOP_MIN_HEIGHT = '565px';

const screen ={
	width: '100%',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}

export const DEVICE_CONSTANTS = {
	//Actions for pushing/pulling/managing workout assignments
	PUSH_ACTION: 'pushWorkoutToDevices',
	PULL_ACTION: 'pullWorkoutFromDevices',
	NO_ACTION: '__NO_ACTION__',
	MANAGE_ACTION: 'manage',
	//Selection Tabs
	availableDevices : 0,
	assignedDevices : 1,
	allDevices : 2,
	inactiveDevices : 3,
	//Default values
	noWorkoutID: -1,
	MANAGE_OPTIONS: {
		none: 0, //no action
		assign: 1, //Assign to an existing group
		create: 2, //create new group
		remove: 3, //remove from existing group
		delete: 4 //Delete the device
	}
}
