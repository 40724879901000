import React, {lazy} from "react";
import {
	PRODUCTION_STATUS,
	ADMIN_DEPT_ID,
	STRENGTH_AND_CONDITIONING_DEPT_ID,
	COACHING_STAFF_DEPT_ID,
	ATHLETE_DEPT_ID,
	OPERATIONS_LEVEL,
	ATHLETE_LEVEL,
	DEVICE_MASTER_LEVEL,
	DEVICE_LEVEL,
	 ASSISTANT_LEVEL
} from "./FITConstants";
import {
	URL_TEAM_WORKOUTS,
	URL_TEAM_BLOCKS,
	URL_LOGIN,
	URL_ACTIVE_WORKOUT,
	URL_TEAMS,
	URL_CREATE,
	URL_PROFILES,
	URL_MY_PROFILE,
	URL_ANALYTICS,
	URL_DEVICES,
	URL_ATHLETE_ASSIGNMENTS,
	URL_ONBOARDING,
	URL_PERSONAL_BLOCK_TEMPLATE,
	URL_PERSONAL_BLOCK_TEMPLATE_NEW,
	URL_PERSONAL_ACTIVE_BLOCK,
	URL_PERSONAL_QUICK_WORKOUT
	} from "./FITConstants";
import {
	AccountCircle,
	PeopleAlt,
	Devices,
	TabletAndroid,
	FitnessCenter,
	AddBox,
	Adb,
	QueryStats,
	AssignmentTurnedIn,
	EmojiEvents, QrCode, Groups, Announcement
} from "@mui/icons-material";
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import {trimURL} from "./UtilityFunctions";
import User from 'fit/system/User';
import LoginRoute from "fit/routes/system/LoginRoute";



//STRENGTH ROUTES
//System Routes




const DeniedRoute = lazy(()=> wait().then(()=>import('fit/routes/system/DeniedRoute')));
const ResetPasswordRoute = lazy(()=> wait().then(()=>import('fit/routes/system/ResetPasswordRoute')));
const DeviceLoginPage = lazy(()=> wait().then(()=>import('../components/Devices/DeviceLoginPage')));
//import UserDirectoryRoute from "fit/routes/shared/UserDirectoryRoute";
const Home = lazy(()=> wait().then(()=>import('../../app/pages/home')));



//Analytics: Limited to coaching staff
const Analytics = lazy(()=> wait().then(()=>import("fit/components/Analytics/")));
const AthleteLeaderboard = lazy(()=> wait().then(()=>import('../components/Analytics/Leaderboard/AthleteLeaderboard')));

//Strength Specific Routes
const CompletedWorkoutRoute = lazy(()=> wait().then(()=>import("fit/routes/shared/CompletedWorkoutRoute")));

//Profile/User
const ProfileRoute = lazy(()=> wait().then(()=>import("fit/routes/shared/ProfileRoute")));
const MyProfileRoute = lazy(()=> wait().then(()=>import("fit/routes/shared/MyProfileRoute")));
const UserOnboarding = lazy(()=> wait().then(()=>import('../components/User/UserOnboarding')));
const UserQRScan = lazy(()=> wait().then(()=>import('../components/User/UserQRScan')));

//Team Management
const TeamsRoute = lazy(()=> wait().then(()=>import('fit/routes/shared/TeamsRoute')));
const SquadsRoute = lazy(()=> wait().then(()=>import('fit/routes/coachingStaff/SquadsRoute')));
const SquadManagementRoute = lazy(()=> wait().then(()=>import('fit/routes/coachingStaff/SquadManagementRoute')));

//Strength, Workout, Exercises
const ActiveWorkout = lazy(()=> wait().then(()=>import("fit/components/Workout/ActiveWorkout")));
const AthleteStrengthTesting = lazy(()=>wait().then(()=>import("fit/components/Workout/AthleteStrengthTesting")));

const WorkoutBlockList = lazy(()=> wait().then(()=>import('fit/components/Workout/WorkoutBlockList')));
const WorkoutsList = lazy(()=> wait().then(()=>import('fit/components/Workout/WorkoutsList/')));

const ExerciseList = lazy(()=> wait().then(()=>import('fit/components/Exercise/ExerciseList')));
const ScheduleRoute = lazy(()=> wait().then(()=>import('fit/routes/strength/ScheduleRoute')));

//Devices
const DeviceList =  lazy(()=>wait().then(()=>import("fit/components/Devices/DeviceList")));
const DeviceMasterProfile = lazy(()=>wait().then(()=>import("fit/components/Devices/DeviceMasterProfile")));
const DeviceMasterRouteHandler = lazy(()=>wait().then(()=>import("fit/components/Devices/DeviceMasterRouteHandler")));

//Block Templates, Personal Programming
const TemplateList = lazy(()=>wait().then(()=>import("fit/components/Workout/TemplateBlocks/TemplateList")));
const TemplateBlock = lazy(()=>wait().then(()=>import("fit/components/Workout/TemplateBlocks/TemplateBlock")));

//Development
const InvitationList = lazy(()=>wait().then(()=>import('fit/components/User/Invites/')));

const DevTeamBlock = lazy(()=>wait().then(()=>import('fit/components/Workout/TeamWorkoutBlock/BlockEditorRoute')));

const iconStyle = {fontSize: 20};

export const DeniedPermissionRoute = {
	path: '*',
	element:  DeniedRoute,
}

const wait=(timeMS = 0)=>{
	return new Promise(resolve =>{
		setTimeout(resolve, timeMS)
	})
}



const user = new User();
const strengthCoach = user.isStrengthCoach(ASSISTANT_LEVEL);
const displayWorkoutMenu = true;// strengthCoach;
console.log('DISPLAY WORKOUT MENU??', displayWorkoutMenu);



const allDepartments = [
	{
		deptID: ADMIN_DEPT_ID,
		managerLevel: 0,
		strictLevel: false
	},
	{
		deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
		managerLevel: 0,
		strictLevel: false
	},
	{
		deptID: COACHING_STAFF_DEPT_ID,
		managerLevel: 0,
		strictLevel: false
	},
	{
		deptID: ATHLETE_DEPT_ID,
		managerLevel: 0,
		strictLevel: false
	}
];


const sharedStrengthAnalyticsRoutes = {
	label: 'Team Analytics',
	menuDisplay: true,
	managerLevel: ASSISTANT_LEVEL,
	strictLevel: false,
	icon: <QueryStats sx={iconStyle}/>,
	subRoutes: [
		{
			label: 'Overview',
			menuDisplay: true,
			managerLevel: ASSISTANT_LEVEL,
			strictLevel: false,
			path: `${URL_ANALYTICS}overview/`,
			element:  <Analytics section={0}/>,
			icon: null
		},
		{
			label: 'Leaderboard',
			menuDisplay: true,
			managerLevel: ASSISTANT_LEVEL,
			strictLevel: false,
			path: `${URL_ANALYTICS}leaderboard/`,
			element:  <Analytics section={1}/>,
			icon: null
		},
		{
			label: 'Team/Athlete Stats',
			menuDisplay: true,
			managerLevel: ASSISTANT_LEVEL,
			strictLevel: false,
			path: `${URL_ANALYTICS}team-statistics/`,
			element:  <Analytics section={2}/>,
			icon: null
		},
		{
			label: "Block Reports",
			menuDisplay: true,
			managerLevel: ASSISTANT_LEVEL,
			strictLevel: false,
			path: `${URL_ANALYTICS}${trimURL(URL_TEAM_BLOCKS)}`,
			element: <Analytics section={3}/>,
			icon: null
		},
		{
			menuDisplay: false,
			path: `${URL_ANALYTICS}${trimURL(URL_TEAM_BLOCKS)}:completedBlockID/`,
			managerLevel: ASSISTANT_LEVEL,
			strictLevel: false,
			element:  <Analytics section={3}/>, //  <CompletedWorkoutBlockRoute/> ,
			label: "Specific Block",
		},
		{
			menuDisplay: true,
			path: `${URL_ANALYTICS}${trimURL(URL_TEAM_WORKOUTS)}`,
			managerLevel: ASSISTANT_LEVEL,
			strictLevel: false,
			element:  <Analytics section={4}/>,
			label: "Workout Reports",
		},
		{
			menuDisplay: true,
			path: `${URL_ANALYTICS}team-settings/`,
			managerLevel: ASSISTANT_LEVEL,
			strictLevel: false,
			element:  <Analytics section={5}/>,
			label: "Team Settings",
		},
		{
			//LOADING WORKOUT BLOCK EDITOR FOR A SPECIFIC WORKOUTID
			menuDisplay: false,
			path: `${URL_ANALYTICS}${trimURL(URL_TEAM_WORKOUTS)}:completedWorkoutID/`,
			managerLevel: ASSISTANT_LEVEL,
			strictLevel: false,
			element: <Analytics section={4} />, //<CompletedWorkoutRoute/>,
			label: "Completed Workouts",
		},
	]
	};

/*
const sharedOperationsRoutes = {
	label: 'Operations',
	menuDisplay: true,
	managerLevel: OPERATIONS_LEVEL,
	strictLevel: false,
	element: <AnalyticsRoute/>,
	icon: <Settings/>,
	subRoutes: [
		{
			label: 'Organization MGMT',
			menuDisplay: true,
			managerLevel: OWNER_LEVEL,
			strictLevel: false,
			path: '/operations/organization-settings/',
			element: <UserDirectoryRoute/>
		},
		{
			label: 'User Directory',
			menuDisplay: true,
			managerLevel: OPERATIONS_LEVEL,
			strictLevel: false,
			path: '/operations/directory/',
			element:  <UserDirectoryRoute/>,
		},
	]
}
*/
const URLSpecificTeamRoute = `${URL_TEAMS}:teamID/`;


export const TestPermissions ={
	label: 'Development',
	deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
	routes: [
		{
			label: 'Dev Test Page',
			menuDisplay: PRODUCTION_STATUS === false,
			managerLevel: 0,
			strictLevel: false,
			path: '/',
			element:  <Home/>,
			icon: <Adb sx={iconStyle}/>
		}
	]
};




//Logged In Permissions for Authorized Users
export const RoutePermissions = [
	{
		label: 'Admin',
		deptID: ADMIN_DEPT_ID,
		routes: []
	},
	TestPermissions,
	{
		label: 'Team Athletics',
		deptID: ATHLETE_DEPT_ID,
		managerLevel: ATHLETE_LEVEL,
		strictLevel: true,
		routes: [
			{
				label: 'Leaderboard',
				menuDisplay: true,
				managerLevel: ATHLETE_LEVEL,
				strictLevel: false,
				path: '/team-leaderboard/',
				element:  <AthleteLeaderboard/>,
				icon: <EmojiEvents sx={iconStyle}/>

			},
			{
				label: 'Team Workouts',
				menuDisplay: true,
				managerLevel: ATHLETE_LEVEL,
				strictLevel: false,
				path: URL_ACTIVE_WORKOUT,
				element:  <ActiveWorkout/>,
				icon: <FitnessCenter sx={iconStyle}/>
			}
			/*,{
				label: 'Practices',
				menuDisplay: true,
				managerLevel: ATHLETE_LEVEL,
				strictLevel: false,
				path: '/athletics/practices/',
				element: <ActiveWorkout/>,
				icon: <Sports/>
			}*/
		]
	},
	{
		label: 'Coaching',
		deptID: COACHING_STAFF_DEPT_ID,
		routes: [
			{
				label: 'Athletes/Rosters',
				menuDisplay: true,
				managerLevel: ASSISTANT_LEVEL,
				strictLevel: false,
				icon: <PeopleAlt sx={iconStyle}/>,
				subRoutes: [
					{
						label: 'Team Roster',
						menuDisplay: true,
						managerLevel: ASSISTANT_LEVEL,
						strictLevel: false,
						path: URL_TEAMS,
						element:  <TeamsRoute/>,
					},
					{
						label: 'Specific Team',
						menuDisplay: false,
						managerLevel: ASSISTANT_LEVEL,
						strictLevel: false,
						path: URLSpecificTeamRoute,
						element:  <TeamsRoute/>,
						icon: <PeopleAlt sx={iconStyle}/>
					},
					{
						label: 'Squad Management',
						menuDisplay: true,
						managerLevel: OPERATIONS_LEVEL,
						strictLevel: false,
						path: '/coaching/squad-management/',
						element:  <SquadManagementRoute/>,
						icon: null
					},
					{
						label: 'Athlete Assignments',
						menuDisplay: true,
						managerLevel: OPERATIONS_LEVEL,
						strictLevel: false,
						path: URL_ATHLETE_ASSIGNMENTS,
						element:  <SquadsRoute/>,
						icon: null
					}
				]
			},
			{
				label: 'Schedule',
				menuDisplay: false,
				managerLevel: ASSISTANT_LEVEL,
				strictLevel: false,
				path: '/coaching/schedule/',
				element:  <ScheduleRoute/>,
				icon: <CalendarViewMonthIcon sx={iconStyle}/>
			},
			sharedStrengthAnalyticsRoutes,
			//sharedOperationsRoutes
		]
	},
	{
		label: 'Strength & Conditioning',
		deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
		routes: [
			{
				label: 'Athletes/Rosters',
				menuDisplay: true,
				managerLevel: ASSISTANT_LEVEL,
				strictLevel: false,
				path: URL_TEAMS,
				element:  <TeamsRoute/>,
				icon: <PeopleAlt sx={iconStyle}/>
			},
			{
				label: 'Specific Team',
				menuDisplay: false,
				managerLevel: ASSISTANT_LEVEL,
				strictLevel: false,
				path: URLSpecificTeamRoute,
				element:  <TeamsRoute/>,
				icon: <PeopleAlt sx={iconStyle}/>
			},

			{
				label: 'Device Admin',
				menuDisplay: true,
				managerLevel: OPERATIONS_LEVEL,
				strictLevel: false,
				icon: <Devices sx={iconStyle}/>,
				subRoutes: [
					{
						menuDisplay: true,
						path: `${URL_DEVICES}admin/account/`,
						managerLevel: OPERATIONS_LEVEL,
						strictLevel: false,
						element:  <DeviceMasterProfile section={'account'}/>,
						label: "Master Account",
					},

					{
						menuDisplay: true,
						path: `${URL_DEVICES}admin/manage/`,
						managerLevel: OPERATIONS_LEVEL,
						strictLevel: false,
						element:  <DeviceMasterProfile section={'manage'}/>,
						label: "Manage Devices",
					},
					{
						menuDisplay: true,
						path: `${URL_DEVICES}admin/settings/`,
						managerLevel: OPERATIONS_LEVEL,
						strictLevel: false,
						element: <DeviceMasterProfile section={'settings'}/>,
						label: 'Settings',
					},
					{
						menuDisplay: false,
						path: `${URL_DEVICES}admin/:section/:masterKey/:subKey?`,
						managerLevel: OPERATIONS_LEVEL,
						strictLevel: false,
						element: <DeviceMasterRouteHandler />,
						label: 'stuff'
					}
				]
			},


			{
				label: 'Device List',
				menuDisplay: true,
				managerLevel: ASSISTANT_LEVEL,
				strictLevel: false,
				path: `${URL_DEVICES}list/`,
				element:  <DeviceList/>,
				icon: <TabletAndroid sx={iconStyle}/>
			},
			{
				label: 'Exercises',
				menuDisplay: true,
				managerLevel: ASSISTANT_LEVEL,
				strictLevel: false,
				path: '/exercises/',
				element:  <ExerciseList/>,
				icon: <AddBox sx={iconStyle}/>
			},
			{
				label: 'Schedule',
				menuDisplay: false,
				managerLevel: ASSISTANT_LEVEL,
				strictLevel: false,
				path: `${URL_TEAM_WORKOUTS}schedule/`,
				element:  <ScheduleRoute/>,
				icon: <CalendarViewMonthIcon sx={iconStyle}/>
			},
			{
				label: 'Schedule : Specific Date',
				menuDisplay: false,
				managerLevel: ASSISTANT_LEVEL,
				strictLevel: false,
				path: `${URL_TEAM_WORKOUTS}schedule/:dateISO/`,
				element:  <ScheduleRoute/>,
				icon: <CalendarViewMonthIcon sx={iconStyle}/>
			},
			sharedStrengthAnalyticsRoutes,
			{
				label: 'Team Workouts',
				menuDisplay: true,
				managerLevel: ASSISTANT_LEVEL,
				strictLevel: false,
				icon: <FitnessCenter sx={iconStyle}/>,
				subRoutes: [
					{
						menuDisplay: true,
						path: `${URL_TEAM_BLOCKS}${trimURL(URL_CREATE)}`,
						managerLevel: ASSISTANT_LEVEL,
						strictLevel: false,
						element: <DevTeamBlock/>, //<NewWorkoutBlockGenerator/> [NEW_WBG]
						label: "Create Block",
					},
					{
						//LOADING WORKOUT BLOCK EDITOR FOR A SPECIFIC WORKOUTID
						menuDisplay: false,
						path: `${URL_TEAM_WORKOUTS}:workoutID/`,
						managerLevel: ASSISTANT_LEVEL,
						strictLevel: false,
						element: <DevTeamBlock/>, //<WorkoutBlockEditorRoute/> [NEW_WBG]
						label: "Specific Workout",
					},
					{
						menuDisplay: false,
						path: `${URL_TEAM_BLOCKS}:workoutBlockID/`,
						managerLevel: ASSISTANT_LEVEL,
						strictLevel: false,
						element:  <DevTeamBlock/> , //<WorkoutBlockEditorRoute/> [NEW_WBG]
						label: "Specific Block",
					},
					{
						menuDisplay: true,
						path: URL_TEAM_BLOCKS,
						managerLevel: ASSISTANT_LEVEL,
						strictLevel: false,
						element: <WorkoutBlockList/>,
						label: "Workout Blocks",
					},
					{
						menuDisplay: true,
						path: URL_TEAM_WORKOUTS,
						managerLevel: ASSISTANT_LEVEL,
						strictLevel: false,
						element:  <WorkoutsList getToday={false}/>,
						label: "All Workouts",
					},
					{
						//Reviewing Completed Workout
						menuDisplay: false,
						path: "/__DELETE_THIS_PATH__/:workoutID/",
						managerLevel: ASSISTANT_LEVEL,
						strictLevel: false,
						element: <CompletedWorkoutRoute/>,
						label: "Completed Workout",
					},

					{
						label: 'Today\'s Workouts',
						menuDisplay: true,
						managerLevel: ASSISTANT_LEVEL,
						strictLevel: false,
						path: `${URL_TEAM_WORKOUTS}today/`,
						element:  <WorkoutsList getToday={true}/>,
						icon: null
					},
					{
						label: 'Active Workout',
						menuDisplay: true,
						managerLevel: ASSISTANT_LEVEL,
						strictLevel: false,
						path: URL_ACTIVE_WORKOUT,
						element:  <ActiveWorkout strengthView={true}/>,
						icon: null
					}
				]
			},
			{
				label: 'Testing',
				menuDisplay: true,
				managerLevel: ASSISTANT_LEVEL,
				strictLevel: false,
				path: '/team-testing/',
				element:  <AthleteStrengthTesting/>,
				icon: <AssignmentTurnedIn sx={iconStyle}/>
			},
			//sharedOperationsRoutes,
		]
	}
	,
	{
		label: 'Personal',
		deptID: null,
		routes: [
			{
				label: 'Device Login',
				menuDisplay: true,
				managerLevel: 0,
				strictLevel: false,
				path: '/device-login/',
				icon: <QrCode/>,
				element: <UserQRScan/>
			},
/*
			{
				label: 'Invites',
				menuDisplay: true,
				managerLevel: 0,
				strictLevel: false,
				path: '/invites/',
				icon: <Groups sx={iconStyle}/>,
				element: <InvitationList/>
			},
			{
				label: 'Notifications',
				menuDisplay: true,
				managerLevel: 0,
				strictLevel: false,
				displayBadge: true,
				icon: <Announcement sx={iconStyle}/>
			},

 */
			{
				label: 'My Workouts',
				menuDisplay: displayWorkoutMenu,
				managerLevel: 0,
				strictLevel: false,
				icon: <FitnessCenter sx={iconStyle}/>,
				subRoutes: [
					{
						label: 'New Program',
						menuDisplay: displayWorkoutMenu,
						managerLevel: 0,
						strictLevel: false,
						path: URL_PERSONAL_BLOCK_TEMPLATE_NEW,
						element: <TemplateBlock/>,
					},
					{
						label: 'Saved Programs',
						menuDisplay: displayWorkoutMenu,
						managerLevel: 0,
						strictLevel: false,
						path: URL_PERSONAL_BLOCK_TEMPLATE,
						element: <TemplateList/>,
					},
					{
						label: 'Active Template',
						menuDisplay: false,
						managerLevel: 0,
						strictLevel: false,
						path: `${URL_PERSONAL_BLOCK_TEMPLATE}:blockKey`,
						element: <TemplateBlock/>,
					},
					{
						label: 'Active Programs',
						menuDisplay: false,
						managerLevel: 0,
						strictLevel: false,
						path: URL_PERSONAL_ACTIVE_BLOCK,
						element: <Home/>,
					},
					{
						label: 'Quick Workout',
						menuDisplay: false,
						managerLevel: 0,
						strictLevel: false,
						path: URL_PERSONAL_QUICK_WORKOUT,
						element: <Home/>,
					},
					/*
					{
						label: 'Upcoming Workouts',
						menuDisplay: true,
						managerLevel: 0,
						strictLevel: false,
						path: `/personal/workout/my-workouts/`,
						element: <Home/>,
					},
					*/
				]
			},
			{
				label: 'Profile',
				menuDisplay: true,
				managerLevel: 0,
				strictLevel: false,
				icon: <AccountCircle sx={iconStyle}/>,
				subRoutes: [
					{
						label: 'Overview',
						menuDisplay: true,
						managerLevel: 0,
						strictLevel: false,
						path: URL_MY_PROFILE,
						element: <MyProfileRoute section={'overview'}/>,
					},
					{
						label: 'Account',
						menuDisplay: true,
						managerLevel: 0,
						strictLevel: false,
						path: `${URL_MY_PROFILE}account/`,
						element: <MyProfileRoute section={'account'}/>,
					},
					{
						label: 'Stats & Metrics',
						menuDisplay: true,
						managerLevel: 0,
						strictLevel: false,
						path: `${URL_MY_PROFILE}metrics/`,
						element: <MyProfileRoute section={'metrics'}/>,
					},
					{
						label: 'Past Workouts',
						menuDisplay: true,
						managerLevel: 0,
						strictLevel: false,
						path: `${URL_MY_PROFILE}past-workouts/`,
						element: <MyProfileRoute section={'past-workouts'}/>,
					},/*
					{
						label: 'Sleep',
						menuDisplay: true,
						managerLevel: 0,
						strictLevel: false,
						path: `${URL_MY_PROFILE}sleep/`,
						element: <MyProfileRoute section={'sleep'}/>,
					},
					{
						label: 'Nutrition',
						menuDisplay: true,
						managerLevel: 0,
						strictLevel: false,
						path: `${URL_MY_PROFILE}nutrition/`,
						element: <MyProfileRoute section={'nutrition'}/>,
					},*/

					{
						label: 'App Settings',
						menuDisplay: true,
						managerLevel: 0,
						strictLevel: false,
						path: `${URL_MY_PROFILE}settings/`,
						element: <MyProfileRoute section={'settings'}/>,
					},
				]
			},
			{
				label: 'Onboarding',
				menuDisplay: false || PRODUCTION_STATUS === false,
				managerLevel: 0,
				strictLevel: false,
				path: URL_ONBOARDING,
				icon: <AccountCircle sx={iconStyle}/>,
				element: <UserOnboarding/>,
			},
		]
	}

];
//Shared Permissions (Likely) Across All Departments
export const SharedPermissions = [
	{
		label: 'My Profile',
		deptIDs: allDepartments,
		menuDisplay: false,
		path: '/my-profile/:section/',
		element:  <MyProfileRoute/>
	},
	{
		label: 'Profiles',
		deptIDs: allDepartments,
		menuDisplay: false,
		path: `${URL_PROFILES}:userID/:section/`,
		element:  <ProfileRoute/>
	},
	{
		label: 'Profiles',
		deptIDs: allDepartments,
		menuDisplay: false,
		path: `${URL_PROFILES}:userID/`,
		element:  <ProfileRoute/>
	},
	{
		label: 'Devices',
		deptIDs: [
			{
				deptID: ADMIN_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: COACHING_STAFF_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			}
		],
		menuDisplay: false,
		path: `${URL_DEVICES}:userID/`,
		element:  <ProfileRoute/>
	},
	{
		label: 'Forced Reset',
		deptIDs:[
			{
				deptID: ADMIN_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: COACHING_STAFF_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			},
			{
				deptID: ATHLETE_DEPT_ID,
				managerLevel: 0,
				strictLevel: false
			}
		],
		menuDisplay: false,
		path: '/forced-reset-password/',
		element:  <ResetPasswordRoute/>
	},
	{
		label: 'login',
		deptIDs: null,
		menuDisplay: false,
		path: URL_LOGIN,
		element: <LoginRoute/>
	}
];
//Permissions limited to Devices
export const DevicePermissions = [
	{
		label: 'Strength & Conditioning',
		deptID: STRENGTH_AND_CONDITIONING_DEPT_ID,
		routes: [

			//MASTER ROUTES
			{
				label: 'Master Policy',
				menuDisplay: false,
				managerLevel: DEVICE_MASTER_LEVEL,
				strictLevel: false,
				path: URL_DEVICES,
				element: <DeviceMasterProfile section={'load'}/>,
				icon: null
			},
			{
				menuDisplay: false,
				path: `${URL_DEVICES}scan-code/`,
				managerLevel: DEVICE_MASTER_LEVEL,
				strictLevel: false,
				element:  <DeviceMasterProfile section={'scan-code'}/>,
				label: "Device Login",
			},
			{
				menuDisplay: false,
				path: `${URL_DEVICES}load/`,
				managerLevel: DEVICE_MASTER_LEVEL,
				strictLevel: false,
				element:  <DeviceMasterProfile section={'load'}/>,
				label: "Load Account",
			},
			{
				menuDisplay: false,
				path: `${URL_DEVICES}manage/`,
				managerLevel: OPERATIONS_LEVEL,
				strictLevel: false,
				element:  <DeviceMasterProfile section={'manage'}/>,
				label: "Manage Devices",
			},
			{
				menuDisplay: false,
				path: `${URL_DEVICES}settings/`,
				managerLevel: OPERATIONS_LEVEL,
				strictLevel: false,
				element: <DeviceMasterProfile section={'settings'}/>,
				label: 'Account/Settings',
			},

			{
				label: 'ActiveWorkout',
				menuDisplay: false,
				managerLevel: DEVICE_LEVEL,
				strictLevel: false,
				path: URL_ACTIVE_WORKOUT,
				element:  <ActiveWorkout/>,
				icon: null
			},
			{
				label: 'My Profile',
				menuDisplay: false,
				managerLevel: DEVICE_MASTER_LEVEL,
				strictLevel: false,
				path: URL_MY_PROFILE,
				element:  <MyProfileRoute/>,
				icon: null
			},
			{
				//LOGIN ROUTE WILL TAKE PERSON TO PROFILE WHEN SUCCESSFULLY LOGGED IN
				path: URL_LOGIN,
				element:  <LoginRoute/>,
			}
		]
	}
]
//Routes only available when logged out
export const LoggedOutPermissions = [
	{
		path: URL_LOGIN,
		element:  <LoginRoute/>,
	},
	{
		path: '/reset-password/',
		element:  <ResetPasswordRoute/>,
	},
	{
		path: '/reset-password/:resetKey/',
		element:  <ResetPasswordRoute/>,
	},
	{
		path: `${URL_DEVICES}login/`,
		element: <DeviceLoginPage/>
	}
];






export default RoutePermissions;
